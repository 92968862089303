body {
  font-family: $font;
  color: $text-color;
}
.text-primary {
  color: $primary !important;
}
.text-secondary {
  color: $secondary !important;
}
