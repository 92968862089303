img.responsive {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
}
img.responsive-full {
  display: block;
  width: 100%;
  height: auto;
}
.main-single-header {
  // padding: 12px 0;
  .lema {
    margin: 0;
    padding: 0 10px;
    text-align: center;
    font-size: 30px;
    line-height: 1.6;
    font-weight: bold;
  }
}
.farmasolidaria-container {
  animation: fadeIn 0.8s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.logo-farmacia {
  img {
    width: 100%;
    height: auto;
    max-height: 130px;
    //max-height: 100%;
    //max-width: 100%;
    display: block;
    object-fit: contain;
    margin: 0 auto;
  }
}
.project {
  padding: 10px 0;
}
.proyecto_actual_nombre {
  text-align: center;
  //font-weight: bold;
  padding: 10px 0;
  margin: 0;
  color: #444;
  font-size: 15px;
  font-family: serif;
}
.project-desc {
  font-size: 22px;
}
.objectives-help {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ccc;
  position: relative;

  .objectives-help-bg {
    height: 100%;
    padding: 15px 15px 4px;

    .objectives-help-title {
      text-align: center;
      font-size: 32px;
      line-height: 1.3;
      font-weight: bold;
      color: #fff;
    }
    .objectives-help-num {
      text-align: center;
      padding: 20px;
      .objectives-help-num_content {
        background-color: #fff;
        color: $primary;
        // font-weight: bold;
        display: inline-block;
        font-size: 38px;
        line-height: 1.9;
        padding: 0 1em;
        border-radius: 0.5em;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      }
    }
    &.bg-1 {
      background-color: rgba(0, 155, 140, 0.5);
    }
    &.bg-2 {
      background-color: rgba(7, 90, 99, 0.5);
    }
    &.auto-h {
      height: 200px;
    }
  }
  .objectives-help_no-current-project {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 52px;
    line-height: 1.2;
    transform: translateY(-50%);
  }
}
.counter {
  background-color: #fff;
  .counter_timesup {
    font-size: 38px;
    line-height: 1;
    letter-spacing: 0.09em;
    padding: 12px;
    text-align: center;
  }
  .counter_row {
    display: flex;
    width: 100%;
    padding: 6px 0;

    .counter_col {
      text-align: center;
      flex: 0 0 25%;
      position: relative;

      .counter_num {
        font-size: 38px;
        line-height: 1;
        letter-spacing: 0.09em;
        margin-bottom: 10px;
      }
      .counter_text {
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0;
      }

      &:before {
        content: ":";
        display: block;
        position: absolute;
        left: -5px;
        top: 50%;
        line-height: 26px;
        height: 26px;
        width: 10px;
        margin-top: -13px;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
}

.donaciones-superiores {
  padding: 8px 0;
  .donaciones-superiores-title {
    font-size: 21px;
    font-weight: bold;
    color: $secondary;
  }
  .donaciones-superiores-p {
    font-size: 13px;
    color: #444;
  }
}
.project-list {
  padding: 10px;
}
.project-success {
  padding: 10px 0;
  .project-success-name {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }
  .project-success-tag {
    background-color: $red-color;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    height: 34px;
    border-radius: 17px;
  }
  p {
    font-size: 13px;
    margin: 0;
    color: #333;
    letter-spacing: 0;
    .euros {
      color: $secondary;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 768px) {
  .main-single-header {
    .lema {
      font-size: 18px;
    }
  }
  .proyecto_actual_nombre {
    font-size: 18px;
    padding-left: 20px;
  }
  .project-desc {
    font-size: 15px;
    font-weight: normal;
  }

  .donaciones-superiores {
    .donaciones-superiores-title {
      font-size: 15px;
    }
    .donaciones-superiores-p {
      font-size: 11px;
    }
  }
  .project-list {
    padding: 10px 20px;
  }

  .objectives-help {
    .objectives-help-bg {
      .objectives-help-title {
        font-size: 25px;
      }
    }
  }

  .project-success .project-success-name {
    font-size: 14px;
  }
}
// @media screen and (max-width: 600px) {
//   .main-single-header {
//     .lema {
//       font-size: 14px;
//     }
//   }
// }
