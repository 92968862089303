html {
  height: 100%;
}
body {
  height: 100%;
  // min-width: 320px;
  letter-spacing: -0.03em;
}
.farmasolidaria-container {
  max-width: 980px;
  margin: 0 auto;
}

.responsive-auto {
  width: 100%;
  height: auto;
  display: block;
}

.btn {
  font-size: 20px;
  padding: 0 16px;
  line-height: 36px;
  border-radius: 18px;
  &.btn-primary {
    background-color: $text-color;
  }
  &.btn-lg {
    font-size: 24px;
    padding: 0 48px;
    line-height: 48px;
    border-radius: 24px;
  }
}
.relative {
  position: relative;
}
