.login-screen {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .login-centered {
    width: 100%;
  }

  .login-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 30px;
    text-align: center;
    font-size: 16px;
    background-color: #fff;
  }
}
.login-container {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 60px;
}
.login-logo {
  margin-bottom: 15px;
  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
  }
}
.login-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
.login-input-line {
  padding: 34px 0;
}
.login-input {
  position: relative;
  $cell-w: 56px;
  $cell-ratio: 1.6;
  $cell-p: 28px;
  $w: (4 * $cell-w) + (3 * $cell-p);
  width: $w;
  margin: 0 auto;

  .login-input_graph {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .login-input_graph-cell {
      border: 2px solid $secondary;
      flex: 0 0 $cell-w;
      height: $cell-w * $cell-ratio;
      border-radius: 0.2 * $cell-w;
    }
  }

  .login-input_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $cell-w * $cell-ratio;

    input {
      position: absolute;
      top: 0;
      left: 13px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      outline: none;
      //background-color: rgba(255, 153, 0, 0.521);
      background-color: transparent;
      line-height: $cell-w * $cell-ratio;
      height: $cell-w * $cell-ratio;
      margin: 0;
      padding: 0;
      width: 160%;
      display: block;
      //text-align: center;
      font-family: monospace;
      font-weight: bold;
      font-size: $cell-w;
      letter-spacing: 53px;
      text-transform: uppercase;

      &.not-caret {
        caret-color: transparent;
      }
    }
  }

  &.login-input-error {
    .login-input_graph {
      .login-input_graph-cell {
        border-color: $red-color;
      }
    }
  }
}

.loading-screen {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.86);

  .loading-screen_graph-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .loading-screen_graph {
      $w: 50px;
      width: $w;
      height: $w;
      border: 8px solid $secondary;
      border-top-color: transparent;
      border-radius: 50%;
      animation: rotateLoading 0.8s infinite linear;
    }
  }
}

@keyframes rotateLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
